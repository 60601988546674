<template>

  <div class="screenx row" style="margin-top:90px; margin-bottom:200px;">

    <div class="offset-12 col-12 offset-xs-12 col-xs-12 offset-sm-12 col-sm-12 offset-md-12 col-md-12 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">

      <div class="row py-5">
        <div class="col-8 offset-2 mb-5 lead text-center">
          <div class="mb-4 text-center display-5" v-html="page.title"></div>
        </div>

        <div class="col-8 offset-2 mb-5 lead text-centerx">
          <p class="mb-2"
            v-for="para in page.details"
            v-bind:key="para['.key']"
            v-html="para"
          >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Company',
  mounted () {
    window.scrollTo(0, 0)
  },
  data () {
    return {
      page: {
        title: 'Company',
        details: [
          'AVIAMS Consult Limited was incorporated on 27th May 2016 and awarded the certificate to commence business on 31st May 2016.',
          'AVIAMS is committed to building global solutions in the areas of Aviation, Information Technology, Finance, General Administration and Management, hence our logo, <a target="_blank" href="https://yen.com.gh/109014-list-adinkra-symbols-meaning-ghana.html#109014">"NYANSAPO" (The Wisdom Knot)</a>. This Akan symbol conveys an objective that wisdom is a requisite to making sound judgments which is at the core of the AVIAMS philosophy. Being intelligent puts one in a position to utilize and apply collective experiences and skills for practical applications. Our dedication to our clients is guided by finding the best possible solution through working with highly experienced experts in safety oversight.',
          'The company has an ambition to develop management applications including civil aviation safety applications and aviation service provider data tools for the storage, retrieval, analysis and developing management reports. AVIAMS is also into developing web based training platforms and conducting formal training in aviation, finance and management.',
          'Africa is our initial market space but our intentions is to reach South America, the Middle East and Asia. To achieve this, we tailor our products and services to incorporate internationalization, which includes software module and training materials developed in the English, French, Portuguese and Spanish languages.',
          'AVIAMS has been assisting the Ghana Civil Aviation Authority prepare for International Civil Aviation Organisation (ICAO) ICVM Safety Audit. We have also developed the <a href="https://adamsws.com" target="_blank">Aviation Data Administration and Management System (ADAMS)</a>, a suite of aviation safety applications for recording safety inspector training and activities and managing the resolution of safety issues including enforcement actions.',
          'Our team consist of experts of multinational background with several years experience in aviation, leadership and management, finance, software development and as certified ICAO instructors.'
        ]
      }
    }
  }
}
</script>
